import {
  WithLightTitle,
  ExclusiveSelectionGroup,
} from '@randstad-lean-mobile-factory/react-components-core';
import { NumberInputDeprecated } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useContext, useEffect } from 'react';

import { EnumTimePackage } from 'src/Services/API';
import { YesNoUnknownEnum } from 'src/Utils/yesNoUnknownEnum';

import { TimeContext } from './../TimeContext';
import HourPackageTimeSelection from './HourPackageTimeSelection';
import styles from './TimeSelection.module.scss';
import { Props } from './TimeSelection.types';

const timePackageEnumMap = {
  HOUR: 'base horaire',
  DAY: 'forfait jour',
};

const TimeSelection = ({ positionBrief, setIsEditAfterSigningModalOpen }: Props) => {
  const timeContext = useContext(TimeContext);
  const timePackageValue = timeContext.watch('timePackage');

  const timeDaysPerYear = timeContext.watch('timeDaysPerYear');

  useEffect(() => {
    if (positionBrief?.signatures !== null && timeContext.control._formState.isDirty) {
      setIsEditAfterSigningModalOpen(true);
      timeContext.setValue(
        'timeDaysPerYear',
        positionBrief?.positionTime?.timeDaysPerYear?.toString()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDaysPerYear, timePackageValue]);

  return (
    <div className={styles.timeContainer}>
      <WithLightTitle title="type de forfait">
        <ExclusiveSelectionGroup<EnumTimePackage | null>
          values={Object.values(EnumTimePackage)}
          getStringValue={key => (key ? timePackageEnumMap[key] : '')}
          getKey={key => key ?? ''}
          selected={timePackageValue}
          setSelected={newSelection => {
            if (positionBrief?.signatures !== null) {
              setIsEditAfterSigningModalOpen(true);
            } else if (newSelection && Object.keys(EnumTimePackage).includes(newSelection)) {
              timeContext.setValue('timePackage', newSelection);
              if (newSelection === EnumTimePackage.HOUR) {
                timeContext.setValue('timeDaysPerYear', '');
              } else {
                timeContext.setValue('timeHoursPerWeek', { hours: null, minutes: null });
                timeContext.setValue('timeWorkingHours', []);
                timeContext.setValue('timeRttCount', '');
                timeContext.setValue('timeOnCallDuty', YesNoUnknownEnum.Unknown);
                timeContext.setValue('timeOnCallDutyDetails', '');
              }
              timeContext.positionInformationHandleSubmit()();
            }
          }}
        />
      </WithLightTitle>
      {timePackageValue === EnumTimePackage.HOUR && (
        <HourPackageTimeSelection
          positionBrief={positionBrief}
          setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
        />
      )}
      {timePackageValue === EnumTimePackage.DAY && (
        <WithLightTitle title="nombre de jours par an" className={styles.timeDaysPerYearContainer}>
          <NumberInputDeprecated
            key="timeDaysPerYear"
            className={styles.timeDaysPerYearInput}
            type="number"
            placeholder="jours"
            defaultValue={undefined}
            control={timeContext.control}
            name="timeDaysPerYear"
            onBlurCapture={timeContext.positionInformationHandleSubmit()}
            min={0}
            max={365}
          />
        </WithLightTitle>
      )}
    </div>
  );
};

export default TimeSelection;
