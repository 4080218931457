import { RandstadAppsPopup, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Menu } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

import styles from './Apps.module.scss';

const Apps = () => {
  const { isProd } = useIsProductionEnvironment();
  const [isOpened, setIsOpened] = useState(false);
  const brand = useSelector(getCurrentBrand);
  return (
    <RandstadAppsPopup
      isProd={isProd}
      brandCode={brand?.brand?.code}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      trigger={
        <div>
          <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpened })}>
            <Menu className={styles.buttonIcon} />
          </Button.Tertiary>
        </div>
      }
    />
  );
};

export default Apps;
