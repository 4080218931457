import {
  Loader,
  PopupMenu,
  SegmentedMenu,
  ThreeDotsButton,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  DocumentPDF,
  IllusEscalierYellow,
  UploadCloud,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useContext, useState } from 'react';
import { useController } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { Signature } from 'src/Assets';
import { useModalWithLocation } from 'src/Hooks/Navigation';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { getEditionRoute } from 'src/Services/Routing';

import ExportModal from '../Modals/ExportModal';
import MailModal from '../Modals/MailModal';
import { ModalsContext } from '../Modals/ModalsContext/ModalsContext';
import SigningModal from '../Modals/SigningModal';

import { BreadCrumb } from './BreadCrumb';
import PositionBriefForm from './PositionBriefForm';
import styles from './PositionBriefModification.module.scss';
import { positionBriefModificationSchema } from './PositionBriefModification.schema';
import { navigation } from './PositionBriefModification.types';
import Sidebar from './Sidebar';

const PositionBriefModification = () => {
  const history = useHistory();
  const modalsContext = useContext(ModalsContext);
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams<{
    firstSection: string;
    companyId: string;
    positionBriefId: string;
  }>();
  const { isError, isLoading, isSuccess, data } = useFetchPositionBriefDetails(
    parseInt(params.positionBriefId)
  );

  const { control } = useFormWithZodResolver({
    schema: positionBriefModificationSchema,
  });

  const emailAddressesController = useController({ control, name: 'emailAddresses' });

  const { open } = useModalWithLocation('recruiting-missions');
  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <BreadCrumb />
        {isSuccess && (
          <PopupMenu
            position="bottom right"
            width={270}
            offsetX={16}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            trigger={<ThreeDotsButton triggered={isOpen} className={styles.trigger} />}
          >
            <MailModal
              positionBriefId={parseInt(params.positionBriefId)}
              selectedMailAddresses={emailAddressesController.field.value ?? []}
              setSelectedMailAddresses={emailAddressesController.field.onChange}
            />
            <PopupMenu.Item
              key="pdfGeneration"
              icon={<DocumentPDF />}
              text="exporter en pdf"
              onClick={() => modalsContext.setIsExportModalOpen(true)}
            />
            {!data.signatures && (
              <PopupMenu.Item
                key="signing"
                icon={<Signature />}
                text="signer le brief de poste"
                onClick={() => modalsContext.setIsSigningModalOpen(true)}
              />
            )}
            <PopupMenu.Item
              key="missionSearch"
              icon={<UploadCloud />}
              text="rattacher à une commande existante"
              onClick={() =>
                open({
                  modalParameters: { briefId: parseInt(params.positionBriefId) },
                })
              }
            />
          </PopupMenu>
        )}
      </div>
      <div className={styles.tabsContainer}>
        <SegmentedMenu
          controls={Object.entries(navigation)}
          selected={params.firstSection}
          keyValueExtractor={([section, { name }]) => ({ key: section, value: name })}
          onSelectionChange={([section]) => {
            history.push(
              getEditionRoute({
                companyId: params.companyId,
                positionBriefId: params.positionBriefId,
                section,
              })
            );
          }}
        />
      </div>
      <div className={styles.tabContainer}>
        <ExportModal isOpen={modalsContext.isExportModalOpen} />
        <SigningModal isOpen={modalsContext.isSigningModalOpen} />
        <Sidebar qualificationId={data?.qualificationId} name={data?.name} />
        {isError && (
          <div className={styles.errorContainer}>
            <div className={styles.errorYellowLadder}>
              <IllusEscalierYellow />
            </div>
          </div>
        )}
        {isLoading && (
          <div className={styles.loader}>
            <Loader heightInRem={8} />
          </div>
        )}
        {isSuccess && <PositionBriefForm />}
      </div>
    </div>
  );
};
export default PositionBriefModification;
