import {
  Button,
  FetchButton,
  ModalDeprecated,
  PopupMenu,
  PopupActions,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useRef } from 'react';

import { IllusBDPWarning } from 'src/Assets';
import { useDeletePositionBrief } from 'src/Hooks/PositionBriefs/useDeletePositionBrief';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './BDPDeletionWarningModal.types';

const BDPDeletionWarningModal = ({ briefId }: Props) => {
  const ref = useRef<PopupActions>(null);
  const deleteMutation = useDeletePositionBrief(briefId);
  const deleteFetchStatus = toFetchStatus(deleteMutation);

  return (
    <ModalDeprecated
      onClose={ref.current?.close}
      title="Attention"
      ref={ref}
      trigger={
        <PopupMenu.Item
          icon={<Trashcan />}
          text="supprimer"
          onClick={ref.current?.open}
          keepOpenOnClick
        />
      }
      icon={<IllusBDPWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={ref.current?.close} text="annuler" />,
        <FetchButton
          title="confirmer"
          fetchStatus={deleteFetchStatus}
          onClick={() => briefId && deleteMutation.mutate()}
          onSuccess={() => {
            ref.current?.close();
            toast.success('le BDP a bien été supprimé', { autoClose: 5000 });
          }}
        />,
      ]}
    >
      <div>Vous vous apprêtez à supprimer définitivement ce bdp. Confirmez vous ce choix ?</div>
    </ModalDeprecated>
  );
};

export default BDPDeletionWarningModal;
