import {
  Button,
  HorizontalTextIconCard,
  ModalDeprecated,
  WithLightTitle,
  BadgeDropdown,
  FetchButton,
  AddButton,
  PopupActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusBDPHeaderCompetitor,
  PlusCircle,
  PlusOpen,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useCallback, useRef } from 'react';

import useCreateCompetitor from 'src/Hooks/Competitors/useCreateCompetitor';
import { CompetitorParams, CompetitorStatus } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { sortCompetitors, statusColorsMap } from 'src/Services/Competitor/competitorStatus';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './CompetitorModal.module.scss';
import { CompetitorSchema } from './CompetitorModal.schema';
import { Props } from './CompetitorModal.types';

const CompetitorModal = ({ positionBriefId, competitorNames }: Props) => {
  const ref = useRef<PopupActions>(null);
  const createCompetitorsMutation = useCreateCompetitor();
  const competitorsFetchStatus = toFetchStatus(createCompetitorsMutation);

  const { control, watch, handleSubmit, setValue, formState, reset } = useFormWithZodResolver({
    schema: CompetitorSchema,
    defaultValues: {
      inputValue: '',
      usedValues: competitorNames,
      competitorList: [],
    },
  });

  const competitorModalHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        createCompetitorsMutation.mutate({
          positionBrief: { id: positionBriefId },
          competitors: values.competitorList,
        });
      }),
    [handleSubmit, positionBriefId, createCompetitorsMutation]
  );

  const inputValue = watch('inputValue');
  const competitorList = watch('competitorList');
  const sortedCompetitors = sortCompetitors(competitorList);

  const addItem = () => {
    if (inputValue.length > 1 && !formState.errors.inputValue) {
      setValue('competitorList', [
        ...competitorList,
        {
          name: inputValue,
          status: CompetitorStatus.LEADER,
        },
      ]);
      setValue('inputValue', '');
    }
  };
  const removeItem = (index: number) => {
    const newCompetitorList = [...competitorList];
    newCompetitorList.splice(index, 1);
    setValue('competitorList', newCompetitorList);
  };
  const updateItem = (index: number, competitor: CompetitorParams) => {
    const newCompetitorList = [...competitorList];
    newCompetitorList[index] = competitor;
    setValue('competitorList', newCompetitorList);
  };
  const onClose = () => {
    reset();
  };
  const onOpen = () => {
    setValue('usedValues', competitorNames);
  };
  return (
    <ModalDeprecated
      ref={ref}
      trigger={
        <AddButton
          className={styles.addButton}
          icon={<PlusOpen className={styles.buttonIcon} />}
          text="ajouter un concurent"
        />
      }
      onOpen={onOpen}
      onClose={onClose}
      title="nouveau concurrent"
      icon={<IllusBDPHeaderCompetitor />}
      iconClassName={styles.icon}
      nested
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            reset();
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => ref.current?.close()}>annuler</Button.Secondary>,
        <FetchButton
          disabled={competitorList.length === 0}
          onClick={competitorModalHandleSubmit()}
          fetchStatus={competitorsFetchStatus}
          title="valider"
          onSuccess={() => ref.current?.close()}
        />,
      ]}
    >
      <WithLightTitle title="nom du concurrent">
        <>
          <TextInput
            containerClassName={classnames(
              { [styles.inputError]: !!formState.errors.inputValue },
              styles.strengthInput
            )}
            name="inputValue"
            control={control}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                addItem();
              }
            }}
            rightIcon={<PlusCircle onClick={addItem} />}
          />
        </>
        <WithLightTitle
          className={styles.container}
          title={
            competitorList.length > 0
              ? pluralFormat(competitorList.length, 'concurrent ajouté')
              : ''
          }
        >
          {sortedCompetitors.map((competitor: CompetitorParams, index) => (
            <div key={competitor.name}>
              {index === 0 && <div className={styles.separator} />}
              <HorizontalTextIconCard
                containerClassName={styles.competitorCard}
                label={competitor.name}
                rightIcon={<Trashcan />}
                onRightIconClick={() => removeItem(index)}
                rightComponent={
                  <BadgeDropdown
                    items={Object.values(CompetitorStatus)}
                    selectedItem={competitor.status}
                    onSelectItem={(item: CompetitorStatus) =>
                      updateItem(index, { name: competitor.name, status: item })
                    }
                    keyValueExtractor={(status: CompetitorStatus) => ({
                      key: status,
                      value: status.toLowerCase(),
                      className: statusColorsMap[status],
                    })}
                  />
                }
              />
              <div className={styles.separator} />
            </div>
          ))}
        </WithLightTitle>
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default CompetitorModal;
