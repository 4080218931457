import { Settings } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  ParametersPopup,
  PopoverActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { CircleRandstad, Document } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';

import { useModalWithLocation } from 'src/Hooks/Navigation';
import { useFetchPerimeter } from 'src/Hooks/Perimeter';
import { useFetchAllowedToExport } from 'src/Hooks/Perimeter/useAllowedToExport';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';

import styles from './ParametersPopOverMenu.module.scss';
import { Props } from './ParametersPopOverMenu.types';

const ParametersPopOverMenu = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const { data: perimeter } = useFetchPerimeter({});
  const canExportQuery = useFetchAllowedToExport();
  const canExport = canExportQuery.data?.includes(perimeter?.id ?? '');
  const canExportQueryFetchStatus = toFetchStatus(canExportQuery);
  const { open } = useModalWithLocation('perimeter-selection');
  const { open: openExport } = useModalWithLocation('export');
  const ref = useRef<PopoverActions>(null);

  return (
    // The props "keys" and "nested" are needed because Flash App is using olf version of reactjs popup
    // TODO: https://gitlab.com/randstad-lean-mobile-factory/build/flash/flash-web/-/issues/109 permet de ne plsu avoir besoin de ces props
    <ParametersPopup
      trigger={
        <div>
          <Button.Tertiary
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Settings
              variant="fill"
              className={classnames({ [styles.openGearButtonIcon]: isOpened })}
            />
          </Button.Tertiary>
        </div>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
      ref={ref}
      children={
        <div>
          <Button.Tertiary
            className={styles.button}
            onClick={() => {
              open({});
              ref.current?.close();
            }}
          >
            <CircleRandstad className={styles.circleRandstad} />
            choix de vos unités
          </Button.Tertiary>
          {canExportQueryFetchStatus === FETCH_STATUS.PENDING && (
            <ContentLoader height="3.5rem" width="100%" uniqueKey="export">
              <rect x="0%" y="5" rx="4" ry="4" width="80%" height="48" />
            </ContentLoader>
          )}
          {canExportQueryFetchStatus === FETCH_STATUS.FULFILLED && canExport && (
            <Button.Tertiary
              className={styles.button}
              onClick={() => {
                openExport({});
                ref.current?.close();
              }}
            >
              <Document className={styles.circleRandstad} />
              export des bdp
            </Button.Tertiary>
          )}
        </div>
      }
      {...props}
    />
  );
};
export default ParametersPopOverMenu;
