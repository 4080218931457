import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  TextAreaWithLightTitle,
  NumberInputDeprecated,
  TripleChoiceButton,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { sanitizeString } from 'src/Utils/sanitizeString';
import { YesNoUnknownEnum } from 'src/Utils/yesNoUnknownEnum';
import { databaseToZodData, zodToDatabaseData } from 'src/Utils/ZodDatabaseTransformer';

import styles from './EnvironmentAndMissions.module.scss';
import { environmentAndMissionsSchema } from './EnvironmentAndMissions.schema';
import Risks from './Risks';

const EnvironmentAndMissions = () => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const id = parseInt(positionBriefId);
  const fetchPositionBriefDetails = useFetchPositionBriefDetails(id);
  const positionBriefFetchStatus = toFetchStatus(fetchPositionBriefDetails);
  const updateMutation = useUpdatePositionBrief();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitted },
  } = useFormWithZodResolver({
    schema: environmentAndMissionsSchema,
    defaultValues: {
      missions: fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.missions ?? '',
      hasManagement: databaseToZodData(
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.hasManagement
      ),
      numberOfPeopleToManage:
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.numberOfPeopleToManage?.toString() ??
        '',
      managementSkills:
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.managementSkills ?? '',
      remoteManagement: databaseToZodData(
        fetchPositionBriefDetails.data?.environmentAndMissionsDetails?.remoteManagement
      ),
      environments:
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.environments ?? '',
      handicapFriendly: databaseToZodData(
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.handicapFriendly
      ),
    },
  });

  const isBriefSigned = useMemo(() => {
    return fetchPositionBriefDetails.data?.signatures !== null;
  }, [fetchPositionBriefDetails.data]);

  const missions = watch('missions');
  const hasManagement = watch('hasManagement');
  const numberOfPeopleToManage = watch('numberOfPeopleToManage');
  const managementSkills = watch('managementSkills');
  const remoteManagement = watch('remoteManagement');
  const environments = watch('environments');
  const handicapFriendly = watch('handicapFriendly');

  const environmentAndMissionsHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        const hasManagement = zodToDatabaseData(values.hasManagement);
        if (!isBriefSigned) {
          updateMutation.mutate({
            id,
            modification: {
              changes: {
                environmentAndMissionsDetails: {
                  missions: sanitizeString(values.missions),
                  hasManagement: hasManagement,
                  numberOfPeopleToManage: parseInt(values.numberOfPeopleToManage ?? '') ?? null,
                  managementSkills: sanitizeString(values.managementSkills),
                  remoteManagement: zodToDatabaseData(values.remoteManagement),
                  environments: sanitizeString(values.environments),
                  handicapFriendly: zodToDatabaseData(values.handicapFriendly),
                },
              },
              _clear: {
                environmentAndMissionsDetails: {
                  numberOfPeopleToManage: !hasManagement,
                  managementSkills: !hasManagement,
                  remoteManagement:
                    !hasManagement || values.remoteManagement === YesNoUnknownEnum.Unknown,
                  hasManagement: hasManagement === undefined,
                  handicapFriendly: values.handicapFriendly === YesNoUnknownEnum.Unknown,
                },
              },
            },
          });
        }
      }),
    [handleSubmit, id, updateMutation, isBriefSigned]
  );

  const submit = environmentAndMissionsHandleSubmit();
  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  useEffect(() => {
    if (isDirty || isSubmitted) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasManagement, handicapFriendly, remoteManagement]);

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue(
        'missions',
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.missions ?? ''
      );
      setValue(
        'hasManagement',
        databaseToZodData(
          fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.hasManagement
        )
      );
      setValue(
        'numberOfPeopleToManage',
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.numberOfPeopleToManage?.toString() ??
          ''
      );
      setValue(
        'managementSkills',
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.managementSkills ?? ''
      );
      setValue(
        'remoteManagement',
        databaseToZodData(
          fetchPositionBriefDetails.data?.environmentAndMissionsDetails?.remoteManagement
        )
      );
      setValue(
        'environments',
        fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.environments ?? ''
      );
      setValue(
        'handicapFriendly',
        databaseToZodData(
          fetchPositionBriefDetails?.data?.environmentAndMissionsDetails?.handicapFriendly
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    missions,
    hasManagement,
    numberOfPeopleToManage,
    managementSkills,
    remoteManagement,
    environments,
    handicapFriendly,
  ]);

  return (
    <div>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.container}>
          <div className={styles.title} />
          <h2 className={styles.subtitle}>missions du candidat</h2>
          <TextAreaWithLightTitle
            title="décrivez les missions liées au poste"
            name="missions"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={missions.length}
            control={control}
            onBlurCapture={environmentAndMissionsHandleSubmit()}
          />
          <div className={styles.separator}></div>
          <h2 className={styles.managementTitle}>management</h2>
          <WithLightTitle
            title="y a-t-il du management sur ce poste ?"
            className={styles.tripleChoiceContainer}
          >
            <TripleChoiceButton control={control} name="hasManagement" />
          </WithLightTitle>
          {hasManagement === YesNoUnknownEnum.Yes && (
            <div>
              <WithLightTitle
                title="nombre de personnes à manager"
                className={styles.numberOfPeopleToManageContainer}
              >
                <NumberInputDeprecated
                  onBlurCapture={environmentAndMissionsHandleSubmit()}
                  name="numberOfPeopleToManage"
                  control={control}
                  type="number"
                  placeholder="nombre"
                  className={styles.numberOfPeopleToManageInput}
                  min={0}
                />
              </WithLightTitle>
              <WithLightTitle
                title="y a-t-il du management à distance sur ce poste ?"
                className={styles.tripleChoiceContainer}
              >
                <TripleChoiceButton control={control} name="remoteManagement" />
              </WithLightTitle>
              <TextAreaWithLightTitle
                title="décrivez le périmètre managérial"
                name="managementSkills"
                counterClassName={styles.counter}
                textAreaClassName={styles.managementSkillsTextArea}
                placeholder="en quelques mots..."
                maxLength={4000}
                valueLength={managementSkills.length}
                control={control}
                onBlurCapture={environmentAndMissionsHandleSubmit()}
              />
            </div>
          )}
          <div className={styles.separator}></div>
          <h2 className={styles.environmentsTitle}>environnement de travail</h2>
          <TextAreaWithLightTitle
            title="décrivez les environnements liés au poste"
            name="environments"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={environments.length}
            control={control}
            onBlurCapture={environmentAndMissionsHandleSubmit()}
          />
          <div className={styles.separator} />
          <h2 className={styles.handicapsTitle}>handicap</h2>
          <WithLightTitle
            title="pourriez-vous adapter le poste à l'handicap ?"
            className={styles.tripleChoiceContainer}
          >
            <TripleChoiceButton control={control} name="handicapFriendly" />
          </WithLightTitle>
          <div className={styles.separator} />
          <h2 className={styles.risksTitle}>risques</h2>
          <Risks
            positionBriefId={id}
            positionBrief={fetchPositionBriefDetails?.data}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
            isBriefSigned={isBriefSigned}
          />
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </div>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des environnements et missions du poste" />
      )}
    </div>
  );
};

export default EnvironmentAndMissions;
