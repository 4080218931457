import { WithLightTitle, DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { CompanyAddress } from 'src/Services/API';

import styles from './CompanyWorkingLocationPicker.module.scss';
import { Props } from './CompanyWorkingLocationPicker.types';

const CompanyWorkingLocationPicker = ({
  companyWorkingLocations,
  selectedCompanyWorkingLocation,
  setSelectedCompanyWorkingLocation,
}: Props) => {
  return (
    <WithLightTitle title="adresse du compte" className={styles.fieldContainer}>
      <DropDown
        className={styles.workingLocationDropDown}
        placeholder={
          companyWorkingLocations
            ? 'choisir parmi les adresses Osmose disponibles'
            : 'aucune adresse renseignée sur ce compte'
        }
        items={companyWorkingLocations ?? []}
        keyValueExtractor={(searchResult: CompanyAddress) => ({
          key: `${searchResult.inseeCode}-${
            searchResult.zipCode
          }-${searchResult.address?.toLowerCase()}`,
          value: `${searchResult.address?.toLowerCase() ?? ''} ${searchResult.zipCode ?? ''} ${
            searchResult.cityName ?? ''
          }`,
        })}
        onSelectItem={(item?: CompanyAddress) => {
          setSelectedCompanyWorkingLocation(item);
        }}
        selectedItem={
          selectedCompanyWorkingLocation?.cityName !== undefined
            ? companyWorkingLocations?.find(
                location =>
                  location.address === selectedCompanyWorkingLocation.address &&
                  location.zipCode === selectedCompanyWorkingLocation.zipCode &&
                  location.cityName === selectedCompanyWorkingLocation.cityName
              ) ?? undefined
            : undefined
        }
        canBeReset
      />
    </WithLightTitle>
  );
};

export default CompanyWorkingLocationPicker;
