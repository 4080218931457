import {
  AddButton,
  Button,
  DropDown,
  ExclusiveSelectionGroup,
  ModalDeprecated,
  PopupActions,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  BinocularFullCut,
  PlusCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useRef } from 'react';

import { ReportingsValidationSchema } from 'src/Containers/Profile/Skills/Skills.schema';
import { EnumReportingFrequencyUnit, Reporting } from 'src/Services/API';
import { frequencyUnitEnumToKeyValue } from 'src/Utils/frequencyUnitEnum';

import styles from './ReportingModal.module.scss';

export interface Props {
  otherReportingNames: string[];
  addReporting: (newReporting: Reporting) => void;
}

const ReportingModal = ({ otherReportingNames, addReporting }: Props) => {
  const ref = useRef<PopupActions>(null);

  const reportingZodResolver = useFormWithZodResolver({
    schema: ReportingsValidationSchema,
    defaultValues: {
      name: '',
      numberPerPeriod: undefined,
      frequencyUnit: undefined,
      modalitiesDescription: '',
    },
  });

  const reportingName = reportingZodResolver.watch('name');
  const reportingNumberPerPeriod = reportingZodResolver.watch('numberPerPeriod');
  const reportingFrequencyUnit = reportingZodResolver.watch('frequencyUnit');
  const reportingModalitiesDescription = reportingZodResolver.watch('modalitiesDescription');

  const onClose = () => {
    reportingZodResolver.reset();
    ref.current?.close();
  };

  return (
    <ModalDeprecated
      ref={ref}
      trigger={<AddButton icon={<PlusCircle />} text="ajouter un nouveau reporting" />}
      icon={<BinocularFullCut />}
      title={'nouveau reporting'}
      onClose={onClose}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            reportingZodResolver.reset();
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => onClose()}>annuler</Button.Secondary>,
        <Button.Primary
          disabled={
            reportingName.length === 0 ||
            otherReportingNames.includes(reportingName) ||
            reportingNumberPerPeriod === undefined ||
            reportingFrequencyUnit === undefined
          }
          onClick={() => {
            addReporting(reportingZodResolver.getValues());
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <div className={styles.form}>
        <WithLightTitle title={'nom du reporting'}>
          <TextInput
            value={reportingName}
            onChange={e =>
              reportingZodResolver.setValue('name', (e.target as HTMLInputElement).value)
            }
          />
        </WithLightTitle>
        <div className={styles.formLine}>
          <WithLightTitle title={'nombre de reporting'}>
            <ExclusiveSelectionGroup
              selected={
                reportingNumberPerPeriod
                  ? {
                      key: reportingNumberPerPeriod.toString(),
                      label: reportingNumberPerPeriod.toString(),
                    }
                  : undefined
              }
              setSelected={freq =>
                reportingZodResolver.setValue('numberPerPeriod', freq ? parseInt(freq?.key) : 0)
              }
              values={Array(5)
                .fill(null)
                .map((_, index) => ({
                  key: (index + 1).toString(),
                  label: (index + 1).toString(),
                }))}
              getKey={item => item.key}
              getStringValue={item => item.label}
            />
          </WithLightTitle>
          <WithLightTitle title={'par'} className={styles.dropdown}>
            <DropDown
              selectedItem={reportingFrequencyUnit}
              canBeReset={false}
              placeholder="choisissez une période"
              items={Object.values(EnumReportingFrequencyUnit)}
              keyValueExtractor={(frequencyUnit: EnumReportingFrequencyUnit) =>
                frequencyUnitEnumToKeyValue(frequencyUnit)
              }
              onSelectItem={(item?: EnumReportingFrequencyUnit) =>
                reportingZodResolver.setValue('frequencyUnit', item!)
              }
            />
          </WithLightTitle>
        </div>
        <TextAreaWithLightTitle
          title="décrivez les modalités de reporting attendues"
          name="modalitiesDescription"
          control={reportingZodResolver.control}
          counterClassName={styles.counter}
          textAreaClassName={styles.textArea}
          placeholder="en quelques mots..."
          maxLength={255}
          valueLength={reportingModalitiesDescription?.length ?? 0}
        />
      </div>
    </ModalDeprecated>
  );
};

export default ReportingModal;
