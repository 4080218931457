import {
  Button,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  PopupMenu,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { RenameField, Wrong } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';

import { BDPCreationIllus } from 'src/Assets';
import { useUpdatePositionBriefName } from 'src/Hooks/PositionBriefs/useUpdatePositionStudyName';
import { toFetchStatus } from 'src/Services/Async';

import styles from './BDPRenameModal.module.scss';
import { BDPNameModificationSchema, Props } from './BDPRenameModal.types';

const BDPRenameModal = React.forwardRef<PopupActions, Props>(
  ({ positionBriefId, positionBriefName }, ref) => {
    const localRef = React.useRef<PopupActions>(null);

    const renameMutation = useUpdatePositionBriefName(positionBriefId);

    const { watch, setValue, handleSubmit, control, reset } = useFormWithZodResolver({
      schema: BDPNameModificationSchema,
      defaultValues: {
        BDPName: positionBriefName,
      },
    });
    const BDPName = watch('BDPName');
    return (
      <ModalDeprecated
        ref={ref ?? localRef}
        trigger={<PopupMenu.Item keepOpenOnClick icon={<RenameField />} text="renommer" />}
        icon={<BDPCreationIllus />}
        title="renommer le bdp"
        onOpen={() => {
          setValue('BDPName', positionBriefName);
        }}
        onClose={() => {
          reset();
        }}
        footerActionsLeft={[
          <Button.Tertiary onClick={() => setValue('BDPName', positionBriefName ?? '')}>
            réinitialiser
          </Button.Tertiary>,
        ]}
        footerActionsRight={[
          <Button.Secondary
            onClick={() => {
              reset();
              localRef.current?.close();
            }}
          >
            annuler
          </Button.Secondary>,
          <FetchButton
            title="valider"
            onClick={handleSubmit(() =>
              renameMutation.mutate({
                newName: BDPName,
              })
            )}
            fetchStatus={toFetchStatus(renameMutation)}
            onSuccess={() => {
              localRef.current?.close();
            }}
          />,
        ]}
      >
        <WithLightTitle title="nom pour votre brief" className={styles.container}>
          <TextInput
            name="BDPName"
            control={control}
            rightIcon={
              <Button variant="tertiary" onClick={() => setValue('BDPName', '')}>
                <Wrong />
              </Button>
            }
          />
        </WithLightTitle>
      </ModalDeprecated>
    );
  }
);

export default BDPRenameModal;
