import { PopupMenu, BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { WhiteCircleBottomArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import styles from './BadgePicker.module.scss';
import { Props } from './BadgePicker.types';

function BadgePicker({ items, selectedItem, onChange, disabled }: Props) {
  const currentItem = items.find(item => item.key === selectedItem);
  return (
    <PopupMenu
      disabled={disabled}
      selectedIds={[selectedItem]}
      trigger={
        <div>
          <BadgeDeprecated
            value={currentItem?.value ?? 'N/A'}
            rightIcon={<WhiteCircleBottomArrow />}
            className={classnames(styles.badge, currentItem?.className)}
          />
        </div>
      }
      position="bottom right"
      arrow={false}
    >
      {items.map(item => (
        <PopupMenu.Item
          key={item.key}
          id={item.key}
          text={item.value}
          onClick={() => onChange(item.key)}
        />
      ))}
    </PopupMenu>
  );
}

export default BadgePicker;
