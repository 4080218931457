import {
  Button,
  FetchButton,
  ModalDeprecated,
  RadioButtonGroup,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BDPExportHeader } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useExportPositionBriefs } from 'src/Hooks/PositionBriefs/useExportPositionBriefs';
import { toFetchStatus } from 'src/Services/Async';

import styles from './ExportBriefsModal.module.scss';

const ExportModal = () => {
  const [exportYear, setExportYear] = useState<Date | undefined>();
  const currentYear = moment().get('year').toString();
  const history = useHistory();
  const exportMutation = useExportPositionBriefs();
  const fetchStatus = toFetchStatus(exportMutation);
  const lastYear = moment().subtract(1, 'year').get('year').toString();
  return (
    <ModalDeprecated
      title="export des données"
      open
      icon={<BDPExportHeader />}
      onClose={() => {
        setExportYear(undefined);
        history.goBack();
      }}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            history.goBack();
          }}
        >
          annuler
        </Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={exportYear === undefined}
          fetchStatus={fetchStatus}
          onSuccess={() => {
            history.goBack();
          }}
          onClick={() => {
            exportMutation.mutate({ startDate: exportYear ?? new Date() });
          }}
        />,
      ]}
    >
      <p className={styles.text}>sur quelle année voulez vous exporter les données ?</p>
      <RadioButtonGroup
        name="yearForExport"
        radioButtonsInfo={[
          {
            id: moment().subtract(1, 'year').startOf('year').toISOString(),
            value: lastYear,
            label: lastYear,
          },
          { id: moment().startOf('year').toISOString(), value: currentYear, label: currentYear },
        ]}
        onChange={(_, id) => setExportYear(new Date(id))}
      />
    </ModalDeprecated>
  );
};

export default ExportModal;
