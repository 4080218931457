import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { EuroSign } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { NumberInputDeprecated } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';

import styles from './AmountSelection.module.scss';
import { Props } from './AmountSelection.types';

export const AMOUNT_PLACEHOLDER = 'montant';

const AmountSelection = ({ title, control, name }: Props) => {
  return (
    <WithLightTitle title={title}>
      <NumberInputDeprecated
        containerClassName={styles.countInput}
        key={`amountSelection-${name}`}
        type="number"
        defaultValue={undefined}
        placeholder={AMOUNT_PLACEHOLDER}
        control={control}
        name={name}
        rightIcon={<EuroSign />}
        min={0}
        max={999999}
      />
    </WithLightTitle>
  );
};

export default AmountSelection;
